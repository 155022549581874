import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link, StaticQuery, graphql } from "gatsby"

const MainNav = ({ location }) => (
  <StaticQuery
    query={graphql`
      query mainNavQuery {
        markdownRemark(
          frontmatter: { templateKey: { eq: "navigation-include" } }
        ) {
          frontmatter {
            main_nav {
              text_logo
              nav_links {
                nav_text
                nav_link
              }
              button_label
              button_link
              background_image_top {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              background_image_bottom {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({
      markdownRemark: {
        frontmatter: {
          main_nav: {
            text_logo,
            nav_links,
            button_label,
            button_link,
            background_image_bottom,
          },
        },
      },
    }) => {
      return (
        <div>
          <BackgroundImage
            Tag="nav"
            fluid={[background_image_bottom.childImageSharp.fluid]}
            className="flex flex-wrap bg-dark px-4 py-2"
            fadeIn={false}
            loading="eager"
          >
            <div className="container mx-auto flex justify-between items-center">
              <Link
                to="/"
                className="text-white text-xl font-semibold"
                style={{ transition: ".5s ease" }}
              >
                {text_logo}
              </Link>
              <div className="py-2">
                {nav_links.map((link) => {
                  return (
                    <Link
                      className="text-white text-md py-2 px-4"
                      to={`/${link.nav_link}/`}
                    >
                      {link.nav_text}
                    </Link>
                  )
                })}
                <Link
                  to={`/${
                    location?.pathname === "/contact/" ||
                    location?.pathname === "/inquire/"
                      ? ""
                      : button_link + "/"
                  }`}
                  className="cursor-pointer bg-transparent hover:bg-white text-md text-white font-semibold hover:text-black py-2 px-4 border border-white hover:border-transparent rounded transition duration-300 ease-in-out"
                  style={{ transition: ".5s ease" }}
                >
                  {location?.pathname === "/contact/" ||
                  location?.pathname === "/inquire/"
                    ? "Back"
                    : button_label}
                </Link>
              </div>
            </div>
          </BackgroundImage>
        </div>
      )
    }}
  />
)

export default MainNav
