import React from "react"

import "../styles/index.css"
import MainNav from "./nav/mainNav"
import Footer from "./footer/footer"

const Layout = ({ location, children }) => {
  return (
    <div className="antialiased flex flex-col text-primary w-full h-screen">
      <MainNav location={location} />
      <main className="flex-1">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
