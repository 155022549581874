import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import SocialLinks from "./socialLink"
import TechStack from "./techStack"

const Footer = () => (
  <StaticQuery
    query={graphql`
      query footerQuery {
        markdownRemark(
          frontmatter: { templateKey: { eq: "navigation-include" } }
        ) {
          frontmatter {
            footer {
              full_name
              slogan
              copyright
              built
            }
          }
        }
      }
    `}
    render={({
      markdownRemark: {
        frontmatter: {
          footer: { full_name, slogan, copyright, built },
        },
      },
    }) => {
      return (
        <footer className="container mx-auto text-center px-4 sm:px-10 mt-8">
          <h2 className="text-3xl font-semibold">{full_name}</h2>
          <p className="py-8">{slogan}</p>
          <SocialLinks />
          <div className="flex flex-col sm:flex-row align-middle items-center sm:justify-center mt-4 mb-8">
            <span className="mb-2 sm:mb-0">{built}:</span>
            <TechStack />
          </div>
          <div>
            <Link
              to="/disclosure/"
              className="hover:underline transition duration-300 ease-in-out cursor-pointer mx-2"
            >
              Disclosure
            </Link>
            <Link
              to="/privacy/"
              className="hover:underline transition duration-300 ease-in-out cursor-pointer mx-2"
            >
              Privacy
            </Link>
            <Link
              to="/terms/"
              className="hover:underline transition duration-300 ease-in-out cursor-pointer mx-2"
            >
              Terms
            </Link>
            <a
              href="/sitemap.xml"
              className="hover:underline transition duration-300 ease-in-out cursor-pointer ml-2"
            >
              Sitemap
            </a>
          </div>
          <p className="mb-8">{copyright}</p>
        </footer>
      )
    }}
  />
)

export default Footer
